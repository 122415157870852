// NavigationBar.module.scss

.navcontainer {
    display: flex;
    width: 100%;
    //justify-content: space-between;
    align-items: center;
    justify-content: center;
    background: darkgreen;
    color: white;
    font-size: 14px;
    padding: 0;
}

.navigation {
    padding: 0px 10px;
    //justify-content: space-around;
    color: white;

    :hover {
        color: red;
    }

    a {
        text-decoration: none;
        color: white;
        display: inline-block;
        padding-right: 15px;
        font-size: 14px;

        &.navActive {
            font-weight: normal;
            text-decoration: underline;
        }
        &.navActive:hover {
            color: red;
        }
        &.navInactive {
            font-weight: normal;
        }

        &.navInactive:hover {
            //cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }
}


.navigation:hover {
      color: red;
      cursor: pointer;
      background-color: transparent;
      text-decoration: underline;
}
