.poientry {
    cursor: pointer;
}

.poientryClosed {
    cursor: pointer;
    text-decoration: line-through red 2px;
    color: red;
}

.resultinfo {
    border: 0px;
    margin-top: 0.1em;
    margin-left: 1em ;
    padding: 0em;
    color: black;

}