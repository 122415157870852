.container {
    display: flex;
    flex-direction: row;
    width: 10em;
    //border-left: 2px  solid black;
}

.clockpicker {
 top: 0px;
 background: #f8f8f8;
 padding: 0px;
 margin: 0px;
 font-size: 8px; 
}


input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0; 
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}
