.container {
    display: grid;
    //border-left: 2px  solid black;

}

.c1x {
  grid-column: 1 / 3;
  grid-row: 1;
}

.c2x {
  grid-column: 1 / 3;
  grid-row: 2;
}

.c3x {
  grid-column: 1 / 3;
  grid-row: 3;
}

