.verticalList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6px;
    padding: 10px;
        text-align: center;
        background: #eee;
}

.horizontal {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.vertical {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.arrival {
       position: absolute;
       left: 8px;
       top: 0px;
       color: darkviolet;
       z-Index: 199;
}

.departure {
       position: absolute;
       left: 8px;
       bottom: 0px;
       z-Index: 199;
}

.cardpaper {
	width: 100%;
	margin-top: 2px;
    margin-bottom: 2px;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.carddetail {
    display: flow;
    position: relative;
    color: black;
    font-size: 12px;
    z-Index: 99;
}