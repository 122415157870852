


.eventMarker {
   filter: invert(34%) sepia(7%) saturate(3703%) hue-rotate(157deg) brightness(102%) contrast(102%);
}

.poiMarkerBrown {
filter: invert(22%) sepia(74%) saturate(2839%) hue-rotate(26deg) brightness(98%) contrast(103%);
	}
	

/* Shining green */
.poiMarkerGreenShining {
	filter: invert(51%) sepia(58%) saturate(3791%) hue-rotate(87deg) brightness(97%) contrast(114%);
}

/* dimmed green */
.poiMarkerGreen {
	filter: invert(65%) sepia(12%) saturate(2300%) hue-rotate(71deg) brightness(91%) contrast(78%);
}

/* A dark gold color - goldenrod */
.poiMarkerYellow {
filter: invert(87%) sepia(21%) saturate(5556%) hue-rotate(349deg) brightness(92%) contrast(84%);
}

/* A dark red */
.poiMarkerRed {
	filter: invert(9%) sepia(77%) saturate(5869%) hue-rotate(2deg) brightness(85%) contrast(112%);
}

/* Dark grey */
.poiMarkerGrey {
	filter: invert(32%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(95%) contrast(86%);
}

.highlightMarker {
   filter: brightness(0) saturate(100%) invert(73%) sepia(67%) saturate(3913%) hue-rotate(314deg) brightness(119%) contrast(88%);
}

.uwhcMarker {
	filter: invert(32%) sepia(97%) saturate(2265%) hue-rotate(214deg) brightness(91%) contrast(89%);
}