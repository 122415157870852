
.Fullrow {
    /* We have FLEX so that the 3 InfoBox instances are layouted in one row, but can warp around */
    display: flex;
    background-color: #222;
    justify-content: space-around;
    width: 100%;
    margin: 0px;
    padding: 0px;
    flex-grow : 0;
}


.title {
   font-size:20px;
   color:white;
   text-align: center;
}

.navigation {
    padding: 0px 10px;
    background: black;
    justify-content: space-around;

    a {
        text-decoration: none;
        color: white;
        display: inline-block;
        padding-right: 15px;
        font-size: 16px;

        &.navActive {
            font-weight: normal;
            text-decoration: underline;
        }
        &.navInactive {
            font-weight: normal;
        }

        &.navInactive:hover {
            //cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }
}



.infoboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    border: 5px solid #222;

    row-gap: 1em;
    column-gap: 1em;
}




.c11 {
    flex-shrink: 1;
    flex-grow: 3;
    flex-basis: 100px;
}

.c12 {
    flex-shrink: 5;
    flex-grow: 3;
    flex-basis: 200px;
}

.c13 {
    flex-shrink: 1;
    flex-grow: 3;
    flex-basis: 100px;
}


