.toplevel {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    border: 0;

    background: #222;
    overflow: hidden;
}


.head {
    display: flex;
    //height:5em;
}

.foot {
    display: flex;
    //height:10em;
}

.content {
    display: flex;
    overflow: scroll;
    background: #222;
    height: 100%;
    overflow: hidden;
}
