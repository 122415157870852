
.toplevel {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow : 0;
}

.list {
    display: flex;
    height: 100%;
    flex-grow: 100;
    padding-right: 8px;
    overflow: scroll;
}

.card {
	// dummy declaration
	position: relative;
	flex-grow: 100;
}


.eventsAndMapGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    
    justify-content: space-around;
    flex-grow : 0;
}


.eventView {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 100px;
    align-items: center;
    
    height: 100%;


    color: lightgray;
    margin-left: 6px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.mapview {
    flex-shrink: 1;
    flex-grow: 5;
    flex-basis: 300px;
    align-items: stretch;
    height: 97%;

    position: relative;

    border: 2px solid black;
    z-index: 0;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
}

.vertbox {
    background: white;
    padding-top: 8px;
    margin-top: 7px;
    padding-bottom: 8px;
    margin-bottom: 7px;
}

.poiBar {
	position: relative;
	background: white;
	opacity: 0.7;
	border: 2px solid midnightblue;
	width: 420px;
	box-sizing: border-box;
	
	z-index: 1000;
    left: 60px;
    top: 10px;
}

.teaserNote {
    position: relative;
    background: red;
    opacity: 0.9;
    border: 2px solid darkred;
    color: black;
    padding: 3px;
    width: 418px;
    box-sizing: border-box;
    
    z-index: 1000;
    left: 0px;
    top: 1px;
}

.readonlyNote {
    position: relative;
    background: goldenrod;
    opacity: 0.9;
    border: 2px solid brown;
    color: black;
    padding: 3px;
    width: 418px;
    box-sizing: border-box;
    
    z-index: 1000;
    left: 0px;
    top: 1px;
}


.poiBarCheckbox {
    padding: 3px;
    opacity: 1;
    border-left: 6px;
}


.poiBarMessage {
    padding: 3px;
    color: black;
    font-size: 0.8em;
    border-left: 6px;
}

.errormsg {
  font-size: 14px;
  color: red;
  background-color: #622;
  border: 2px solid red;
  padding: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
}