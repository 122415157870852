
.dialogcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6px;
    padding: 6px;
    text-align: center;
    font-size: 9px;
}

.poientry {
    cursor: pointer;
}

.container {
    display: grid;
    margin: 3px;
    padding: 3px;
    row-gap: 1em;
    column-gap: 1em;
}


.cadd {
  grid-column: 8;
  grid-row: 2;
}


.c2x {
  grid-column: 1 / 9;
  grid-row: 1;
}


.c11 {
  grid-column: 1/3;
  grid-row: 2;
}

.c12 {
  grid-column: 3/5;
  grid-row: 2;
}

.c13 {
  grid-column: 6/7;
  grid-row: 2;
}


.cconfirm {
  grid-column: 7;
  grid-row: 2;
}



.c23x {
  grid-column: 1 / 9;
  grid-row: 3;
}


.c31 {
  grid-column: 1;
  grid-row: 4;
}

.c32 {
  grid-column: 2;
  grid-row: 4;
}

.c33 {
  grid-column: 3;
  grid-row: 4;
}

.c34 {
  grid-column: 4;
  grid-row: 4;
}

.c35 {
  grid-column: 5;
  grid-row: 4;
}
.c36 {
  grid-column: 6;
  grid-row: 4;
}
.c37 {
  grid-column: 7;
  grid-row: 4;
}

