
.errormsg {
  font-size: 16px;
  color: red;
  background-color: #622;
  border: 2px solid red;
  padding: 0.5em 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.tourTitle {
   font-size:20px;
}

.tourLocation {
   font-size:16px;
}

.tourDetails {
   font-size:12px;
}

.verticalList {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 95%;

    //flex: 0.1 1 1 2;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    background: #222;
    color: white;
    overflow: none;
    //gap: 2px;
}

.flexHorizontal {
    display: flex;
    flex-direction: row;
    width: 100%;

    //flex: 0.1 1 1 2;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    overflow: none;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.flexVertical {
    display: flex;
    flex-direction: column;
    width: 100%;

    //flex: 0.1 1 1 2;
    align-items: normal;
    box-sizing: border-box;
    text-align: center;
    overflow: none;
    flex-wrap: nowrap;
    gap: 10px;
    margin: 0;
    padding: 0;
}



.horButtonsRow {
    display: flex;
    margin: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    background: white;
    font-size: 0.4em;
}

.horButtonsRow button {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px dashed;
    text-align: center;
}

.horButtonAndTextBox {
    display: flex;
    flex-direction: column;
    font-size: 2.2em;
    align-items: center;
}

.horButtonAndTextBox button {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px dashed;
    text-align: center;
    border-radius: 16px;
}

.horList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 6px;
    padding: 6px;
        text-align: center;
        background: #eee;
}

.modal {
   border: 10px solid white;
   padding: 10px;
   margin: 10px;
   background: lightgray;
   border-top-left-radius: 8px;
   border-radius: 8px;
   width: 60%;
   z-index: 99;
}

.modalcontent {
   margin: 10px;
   width: 95%;
}

a.tooltip:link {
      color: lightblue;
      background-color: transparent;
      text-decoration: none;
    }

a.tooltip:visited {
      color: lightblue;
      background-color: transparent;
      text-decoration: none;
    }

a.tooltip:hover {
      color: white;
      background-color: transparent;
      text-decoration: underline;
    }

a.tooltip:active {
      color: blue;
      background-color: transparent;
      text-decoration: underline;
}


a.tooltipdark:link {
      color: darkblue;
      background-color: transparent;
      text-decoration: none;
    }

a.tooltipdark:visited {
      color: darkblue;
      background-color: transparent;
      text-decoration: none;
    }

a.tooltipdark:hover {
      color: red;
      background-color: transparent;
      text-decoration: underline;
    }

a.tooltipdark:active {
      color: blue;
      background-color: transparent;
      text-decoration: underline;
}
