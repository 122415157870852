
.map {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 200px;
}

.popupHorFlex {
    display: flex;
    margin: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 3px;
    background: #222;
    color: white;
    
    a {
        text-decoration: none;
        color: white;
        display: inline-block;
        padding-right: 15px;
        font-size: 14px;

        &.navActive {
            font-weight: normal;
            text-decoration: underline;
        }
        &.navActive:hover {
            color: red;
        }
        &.navInactive {
            font-weight: normal;
        }

        &.navInactive:hover {
            //cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }

}


