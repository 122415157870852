
.clockpicker {
 position: relative;
 top: 4px;
 background: #f8f8f8;
 padding: 14px;

}

.stayduration {
 background: #f8f8f8;
 min-width: 80px;
}

.dialog {
    text-align: left;
    font-size: 12px;
    color: #666;
}

.dialogcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6px;
    padding: 6px;
    text-align: center;
    font-size: 12px;
}

.textarea {
      width: 100%;
      margin-right: 20px;
}

.textareaicon {
      margin-left: 20px;
}

.ta {

      border-spacing: 15px 5px;
}