// NavigationBar.module.scss

.Infocolumn {
  //position: relative;
  //width: 20%;
  border-radius: 13px 13px 0px 0px;
  padding: 2px;
  padding-bottom: 2px;
  border: 5px solid #222;
  color: aliceblue;
}

.InfocolumnHead {
  font-size: calc(8px + 1vmin);
  text-align: center;
  padding-bottom: 5px;
}

.InfocolumnMsg {
  font-size: calc(8px + 0.8vmin);
  background: #333;
  padding: 8px;
  padding-top: 2px;
  padding-bottom: 14px;
  color: aliceblue;
}