
.list {

    top: 0;
    bottom: 0;
    overflow: scroll;

    color: white;

}
